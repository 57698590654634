@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap');

body {
    font-family: 'Inter', sans-serif !important;
 }
 
:root {
    --themedark: #1F1A17;
    --themegray: #707070;
    --thrmelightgray: #E8E7E7;
    --white: #fff;
    --black: #000;
    --radius: 20px;
    --cardshadow: 6px 6px 6px #00000029 !important;
}

.container {
    max-width: 1620px;
}

/*global css*/
.main-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.sub-head {
    color: var(--white);
    font-weight: 700;
    margin-top: 0;
    font-size: 2.5rem;
}
 
button.btn {
    transition: all 0.5s ease !important;
}

.btn-theme {
    background-color: var(--themedark) !important;
    border-color: var(--themedark) !important;
    color: var(--white) !important;
    border-radius: 12px !important;
    font-weight: 700 !important;
    transition: all .5s !important;
    padding: 0.7rem 3rem !important;
}

.btn-theme:hover { 
    background-color: var(--themegray) !important;
    border-color: var(--themegray) !important;
    color: var(--white) !important;
    box-shadow: 5px 5px 0 var(--themedark);
}

.btn-white {
    background-color: var(--white) !important;
    border-color: var(--white) !important;
    color: var(--themedark) !important;
}

.btn-white:hover {
    border-radius: 20px;
    background-color: #707070 !important;
    border-color: #707070 !important;
    color: var(--white) !important;
}

.form-control:focus {
    box-shadow: none !important;
    outline: none !important;
}

.wrapper {
    padding: 40px 0 0px 0;
}

.section-head {
    font-size: 2.5rem;
    color: var(--themedark);
    font-weight: 700;
    margin-bottom: 0;
}

/*header css*/

header .navbar {
    text-align: center;
    border-bottom: 2px var(--themegray) solid;
}

header .navbar .nav-link {
    font-weight: 700;
    font-size: 1.1rem;
    color: var(--themedark);
}

.nav-left .nav-link {
    margin-right: 25px;
}

.nav-right .nav-link {
    margin-left: 30px;
}

.d-logo {
    width: 200px;
}

/*home css*/

#carouselHome .carousel-inner .carousel-item img {
    border-radius: 20px;
}

.carousel.carousel-fade .carousel-item {
    display: block;
    opacity: 0;
    transition: opacity ease-out .7s;
}

.carousel.carousel-fade .carousel-item.active {
    opacity: 1 !important;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
    background-color: rgba(0,0,0,0.8);
    border-radius: 50%;
    background-size: 24px 24px !important;
    width: 2.4rem !important;
    height: 2.4rem !important;
}

.carousel-caption {
    background-color: rgba(0,0,0,0.5) !important;
    bottom: 0 !important;
    right: 0 !important;
    width: 40%;
    padding: 2.7rem 5rem 2.7rem 2.7rem !important;
    text-align: left !important;
    border-radius: 20px;
    top: 0;
    left: auto !important;
}

.carousel-caption h2 {
    font-size: 3.5rem;
    font-weight: 700;
    text-shadow: 3px 3px 5px #707070;
    border-bottom: 1px #d1d0d0 solid;
    padding-bottom: 30px;
    margin-bottom: 30px;
}

.carousel-caption p {
    font-size: 1rem;
}

.carousel-control-next, .carousel-control-prev {
    width: 7% !important;
}

.hop-head {
    border-bottom: 1px solid var(--themedark);
    padding-bottom: 30px;
    margin-bottom: 35px;
}

.hop-head .col-6 {
    display: flex;

align-items: center;}

.home-our-products {
    background-color: var(--thrmelightgray);
    padding: 50px 0;
}

.home-slider {
    padding-bottom: 50px;
}

.our-client {
    padding: 20px 0;
}

.hptab-head button
{
    line-height: 25px;
}

/*footer css*/
footer {
    background: var(--themedark);
    padding: 30px 0;
    margin-top: auto;
    font-size: 0.9rem;
}

.newsletter-card {
    background-color: #45403D !important;
    border-radius: 20px;
    color: var(--white) !important;
}

.newsletter-card .card-body {
    padding: 30px 50px;
}

.newsletter-card .card-body p {
    font-size: 1rem;
}

.nl-form {
    display: flex;
}

.nl-form .form-control {
    border-radius: 12px;
    background-color: #6B6663;
    border-color: #6B6663;
    color: var(--white);
}

.nl-form .form-control:focus {
    background-color: #6B6663;
    border-color: #6B6663;
    color: var(--white);
}

.nl-form .form-control::placeholder {
    color: var(--white);
}

.nl-form .btn {
    border-radius: 12px !important;
    width: 140px;
    margin-left: 18px;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

.social-icons {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top:20px
}

.social-icons a {
    background: var(--white);
    color: var(--themedark);
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transition: all 0.5s ease;
    margin-left: 12px;
}

.social-icons a:hover {
    background: var(--white);
    color: var(--themedark);
    border-radius: 50%;
}

.social-icons a:first-child
{margin-left: 0;
}

.fs-area {
    padding: 40px 0;
}

.fl-area p {
    max-width: 60%;
    color: var(--white);
    margin: 5px 0 0 0;
    font-size: 0.9rem;
}

.footer-logo {
    width: 200px;
    filter: brightness(0) invert(1);
}

.copyright-area {
    color: var(--white);
    padding-top: 10px;
}

.copyright-area a {
    color: var(--white);
    text-decoration: none;
}

.hp-card {
    box-shadow: var(--cardshadow);
    border: 0 !important;
    border-radius: 25px !important;
}

.hp-img-card .card-body {
    padding: 0;
}

.hptab-content img {
    border-radius: 20px;
    position: relative;
    z-index: 2;
    width: 100%;
}

.hptab-content h3 {
    background: var(--themedark);
    color: var(--white);
    z-index: 1;
    border-radius: 0px 0px 20px 20px;
    padding: 30px 20px 14px;
    font-size: 1.6rem;
    font-weight: 700;
    position: relative;
    bottom: 0;
    margin-top: -16px;
    margin-bottom: 0;
}

.hptab-content p {
     min-height: 197px; 
    padding: 30px;
    font-size: .9rem;
    overflow: hidden;
    margin-bottom: 0px;
}

.hptab-head {
    border: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    border: 0;
}

.hptab-head .nav-item {
    width: 50%;
    margin-bottom: 14px;
}

.hptab-head .nav-link {
    border: 0;
    background: var(--white);
    border-radius: 12px;
    color: var(--themedark);
    width: 100%;
    text-align: left;
    padding: 1rem 0.9rem;
    font-weight: 600;
    font-size: 1.3rem;
}

.hptab-head .nav-link:hover {
    border-color: transparent !important;
    color: #000;
}

.hptab-head .nav-link.active {
    border: 0;
    background: var(--themedark) !important;
    border-radius: 12px;
    color: var(--white) !important;
    width: 100%;
    text-align: left;
    padding: 0.7rem 0.9em;
    font-weight: 600;
}

.slick-prev:before, .slick-next:before {
    color: var(--themedark) !important;
}

.page-head-section {
    padding: 0px 0 30px 0;
}

.page-head {
    position: relative;
    margin-bottom: 20px;
}

.page-head .overlay {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background-color: rgba(0,0,0,0.3);
    color: var(--white);
    display: flex;
    align-items: center;
}

.page-head img {
    border-radius: 20px;
}

.page-head h1 {
    font-size: 4em;
    font-weight: 700;
    text-shadow: 3px 3px 5px #3c3b3b;
    color: var(--white);
    padding-left: 60px;
}

.sub-section, .our-strength {
    padding: 10px 0 30px 0;
}

.we-tech-main { 
    display: flex;   
}

.sub-section .card { 
    background: rgb(232,231,231);
    background: linear-gradient(180deg, rgba(232,231,231,1) 0%, rgba(255,255,255,1) 100%);
    border: 0;
    border-radius: 20px;
    color: var(--black);
}

.sub-section .card .card-body { 
    padding: 3.6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.sub-section .card h2 {
    font-weight: 600;
    margin-bottom: 22px;
    font-size: 3rem;
}

.wat-tech {
    background: rgb(232,231,231);
    background: linear-gradient(180deg, rgba(232,231,231,1) 0%, rgba(255,255,255,1) 100%);
    border-radius: 20px;
}

.text_bg_animation {
    font-size: 5vw;
    color: var(--themedark);
    /* text-transform: uppercase; */
    line-height: 1;
    width: 100vw;
    max-width: 100%;
    overflow: hidden;
    z-index: 1;
    padding: 15px 0;
    font-weight: bold;
}

.text_bg_animation .anitext {
    white-space: nowrap;
    will-change: transform;
    transition: 0.3s ease-in-out;
    animation: marquee 30s linear infinite;
}

.text_bg_animation .anitext span {
    padding: 0 4vw;
}

@keyframes marquee {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-60%);
    }
}

.about-marquee {
    padding: 30px 0;
}

.text_sm_am {
    text-align: center;
    font-size: 1.4rem;
    padding: 1.3rem 0 0 0;
    font-weight: 500;
}

.our-vision-section {
    padding: 30px 0 50px 0;
}

.our-vision-section img {
    border-radius: 20px;
}

.card-dark {
    background: var(--themedark);
    color: var(--white);
    border-radius: 20px;
}

.card-light {
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(232,231,231,1) 100%);
    border-radius: 20px;
    color: var(--themedark);
    border: 0;
}

.product-list-section .card .card-body {
    padding: 1.5rem;
}

.our-vision-section .card .card-body h3 {
    font-size: 3rem;
    margin: 0 0 20px 0;
}

.product-list-section {
    padding: 0 0 30px 0;
    margin-top: -35px;
}

.product-list-section .product-main {
    background: rgb(232,231,231);
    background: linear-gradient(180deg, rgba(232,231,231,1) 0%, rgba(255,255,255,1) 100%);
    border-radius: 20px;
    /*border: 1px solid var(--themegray);*/
    padding: 5.5rem 2.5rem 2.5rem 2.5rem;
}

.product-card {
    border: 1px solid var(--themegray);
    border-radius: 20px;
    box-shadow: var(--cardshadow);
    margin-bottom: 40px;
}

/*product-list*/
.product-main .form-label {
    font-weight: 700;
}

.product-main .filter-card {
    padding: 1rem;
}

.product-main .form-check-input[type=checkbox] {
    border-radius: 6px;
}

.product-main .form-check-input {
    border: 2px solid #afafaf !important;
    width: 1.5em !important;
    height: 1.5em !important;
}

.product-main .form-check-input:focus {
    border-color: #afafaf;
    outline: 0;
    box-shadow: none;
}

.product-main .form-check-input:checked {
    background-color: #322E2B;
    border-color: #322E2B !important;
}

.product-main .table>thead>tr>th {
    background-color: var(--themedark);
    vertical-align: middle;
    padding: 0.5rem 0.5rem !important;
    color: var(--white);
    border: 0;
}

.product-main .table>thead>tr>th:first-child {
    border-radius: 15px 0 0 15px;
}

.product-main .table>thead>tr>th:last-child {
    border-radius: 0 15px 15px 0;
}

.product-main .table>tbody>tr>td {
    vertical-align: middle;
    padding: 0.5rem 0.5rem !important;
}

.product-main .list-group-flush>.list-group-item button {
    color: #322E2B;
    text-decoration: none;
    font-weight: 600;
}

.product-main .list-group-item.active button {
    color: #fff;
}


.modal h3 {
    border-bottom: 1px #707070 solid;
    padding-bottom: 18px;
    margin-bottom: 35px;
    font-size: 1.5rem;
}

.modal-content {
    background: #3B3A39;
    color: #fff;
    border-radius: 20px;
}

.modal-d {
    border-left: 1px #afafaf solid;
}

.modal-form {
    padding: 2rem;
}

.modal-content .form-control {
    background-color: #6B6663;
    color: #fff;
    border: 1px #707070 solid;
}

.modal-content .form-control::placeholder {
    color: #fff;
}

.modal-header .btn-close {
    opacity: 1;
    background: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e");
    background-color: var(--themedark);
    background-size: 15px 15px;
    background-repeat: no-repeat;
    background-position: center center;
    border: 2px solid var(--themegray);
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 2rem;
}

.product-main .form-check-input {
    margin-top: 0;
}

.ptd-btn a {
    margin-left: 10px;
    background-color: var(--themedark);
    border-color: var(--themegray);
}

.category-name-card .list-group {
    padding: 1rem 0 1rem 0;
}

.category-name-card .list-group li {
    padding: 0;
}

.category-name-card .list-group li button {
    display: block;
    padding: 0.5rem 1rem;
    border: 0;
    background: transparent;
    display: block;
    width: 100%;
    text-align: left;
}

.category-name-card .list-group-item {
    transition: 0.3s ease-in-out;
}

.category-name-card .list-group-item:hover {
    background: var(--thrmelightgray);
}

.product-main .list-group-item.active {
    z-index: 2;
    color: #fff;
    background: var(--themedark);
    border-color: var(--themedark);
    box-shadow: 6px 6px 6px #00000029;
}

.category-name-card .card-body {
    padding: 0 !important;
}

.table-link {
    color: #000;
    cursor: pointer;
}

.product-modal .modal-dialog {
    padding: 0;
    background: transparent;
}

.modal-backdrop.show {
    opacity: inherit;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
}

.modal-header {
    background: rgb(248,248,248);
    background: linear-gradient(180deg, rgba(248,248,248,1) 0%, rgba(188,188,187,1) 100%);
    color: var(--themedark);
    padding-left: 2rem;
    padding-right: 2rem;
    border-radius: 20px 20px 0px 0px;
    border-bottom: 1px solid var(--themegray);
}

.modal-title {
    font-weight: 700;
}

.btn-close {
    background-color: var(--themedark);
}


.vertical-timeline::before
{background-color:#000 !important}

.vertical-timeline-element-icon
{
    box-shadow:none !important;
    border:4px solid #111;
}
  
.vertical-timeline-element-date{
    font-size:25px !important;
}
.vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date
{
    top:-8px !important;
}
.vertical-timeline-element-title 
{
    font-size:1rem;
}

.vertical-timeline-element-content
{border:3px solid #ddd}



.custom-timeline-element .vertical-timeline-element-content {
    background: rgb(255, 255, 255);
    color: #000;
}

.custom-timeline-element .vertical-timeline-element-content::before {
    border-right: 7px solid rgb(0, 0, 0);
}

.custom-timeline-element .vertical-timeline-element-icon {
    background: rgb(255, 255, 255);
    color: #fff;
}

.vertical-timeline-element-content-arrow
{border-right: 7px solid #000000 !important}


.custom-timeline-element .vertical-timeline-element-content {
    background: rgb(255, 255, 255);
    color: #000;
}

.custom-timeline-element .vertical-timeline-element-content::before {
    border-right: 7px solid rgb(0, 0, 0);
}

.custom-timeline-element .vertical-timeline-element-icon {
    background: rgb(255, 255, 255);
    color: #fff;
}

.knowmorebtn
{
    background-color: #000000;
    color:#fff;
    padding: 0px 10px;
    border-radius: 20px;
    font-size: 11px;
    text-decoration: none;
    
}


/*responsive css start*/
@media only screen and (max-width: 768px) {

    .w-75 {
        width: 100% !important; /* Override the default width */
    }
    .nav-right .nav-link {
        margin-left: 0px;
    }

    .nav-left .nav-link {
        margin-right: 0px;
    }

    .navbar-collapse {
        background: #fafafa;
    }

    .carousel-control-prev-icon, .carousel-control-next-icon {
        background-color: rgba(0,0,0,0.9);
        border-radius: 50%;
        background-size: 16px 16px !important;
        width: 2.4rem !important;
        height: 1.5rem !important;
    }

    .section-head {
        font-size: 1.7rem;
    }

    .btn-theme {
        padding: 0.7rem 1rem !important;
    }

    .m-logo {
        width: 160px;
    }

    .product-list-section .product-main
    {padding:10px;}

    .category-name-card
    {margin-top:40px;
        margin-bottom:0}


    .product-list-section .card .card-body 
        {padding:10px}

  .social-icons 
  {justify-content: start;}

  .hptab-head .nav-item
  {width:100%}
  }

  

  @media only screen and (max-width: 400px) {
    .section-head {
        font-size: 1.4rem;
    }

    .btn-theme {
        padding: 0.7rem 1.6rem !important;
    }

    .hptab-head .nav-item
    {width:100%}

    .product-list-section .product-main
    {padding:10px;}

    .product-card
    {margin-top:40px;
        margin-bottom:0}
  }

  @media only screen and (max-width: 359px) {
    .section-head {
        font-size: 1.3rem;
    }

    .btn-theme {
        padding: 0.7rem 1.2rem !important;
        font-size: 0.9rem;
    }

    .hptab-head .nav-item
    {width:100%}

    .product-list-section .product-main
    {padding:10px;}

    .product-card
    {margin-top:40px;
        margin-bottom:0}
  }

  @media only screen and (max-width: 320px) {
    .section-head {
        font-size: 1rem;
    }

    .btn-theme {
        padding: 0.7rem 1rem !important;
        font-size: 0.8rem;
    }

    .hptab-head .nav-item
    {width:100%}

    .product-list-section .product-main
    {padding:10px;}

    .product-card
    {margin-top:40px;
    margin-bottom:0}
  }