.negative-mt-4
{margin-top:-40px}

.accordion-button
{display:block}

.responsibility ul li
{
    list-style-type: none;
}

.responsibility ul 
{
    margin:0;
    padding-left: 0;
}

.career-form-card
{background-color:#F3F3F3;}

.custom-container
{
    font-size: 15px;
    display:block;
    width:93%;
    margin-top:100px;
    

}

.body-text
{
    font-size: 15px !important;
}
 .small-text
 {
    font-size:14px;
 }
.posted-date
{
    font-size: 13px;
    color:#a3440e;
    line-height: 24px;
}

.career-list-section .accordion-flush .accordion-item
{
    padding:4px;
    
    border: 1px solid #ddd;
    border-bottom-width: 5px;
}

.career-list-section .accordion-flush .accordion-item:last-child
{ border-bottom: 5px solid #000;}

.accordion-button:not(.collapsed)
{background: #f3f3f3;}